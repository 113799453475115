import Vue from 'vue';
import VueRouter from 'vue-router';

import store from './../store/store.js';

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import(/* webpackChunkName: "home" */ '../pages/Home.vue')
	},
	{
		path: '/diy-ticketing',
		name: 'diy',
		component: () => import(/* webpackChunkName: "diy" */ '../pages/Diy.vue')
	},
	{
		path: '/custom-ticketing',
		name: 'custom',
		component: () => import(/* webpackChunkName: "custom" */ '../pages/Custom.vue')
	},
	{
		path: '/imprint',
		name: 'imprint',
		component: () => import(/* webpackChunkName: "imprint" */ '../pages/Imprint.vue')
	},
	{
		path: '/privacy',
		name: 'privacy',
		component: () => import(/* webpackChunkName: "privacy" */ '../pages/Privacy.vue')
	},
	{
		path: '/contact',
		name: 'contact',
		component: () => import(/* webpackChunkName: "contact" */ '../pages/Contact.vue')
	},
	{
		path: '/ticketing-in-action',
		name: 'ticketing-in-action',
		component: () => import(/* webpackChunkName: "ticketing-in-action" */ '../pages/BestPractice.vue')
	},
	{
		path: '/references',
		name: 'references',
		component: () => import(/* webpackChunkName: "references" */ '../pages/References.vue')
	},
	{
		path: '/ticket-scanner-setup',
		name: 'ticket-scanner-setup',
		component: () => import(/* webpackChunkName: "ticket-scanner-setup" */ '../pages/TicketScannerSetup.vue')
	},
	{
		path: '*',
		name: 'notfound',
		component: () => import(/* webpackChunkName: "notfound" */ '../pages/NotFound.vue')
	}
]

const Router = new VueRouter({
	routes,
	// eslint-disable-next-line no-unused-vars
	scrollBehavior (to, from, savedPosition) {
		return { x: 0, y: 0 }
	}
});

// eslint-disable-next-line no-unused-vars
Router.beforeEach((to, from, next) => {
	store.commit('setLoading');
	next();
});

// eslint-disable-next-line no-unused-vars
Router.afterEach((to, from) => {
	store.commit('unsetLoading');
});

export default Router;
